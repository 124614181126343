import './App.css';
import ValidationUI from './validationui.jsx';
import * as Sentry from "@sentry/react";
import {
  QueryClient,
  QueryClientProvider
} from "@tanstack/react-query";
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init({
  dsn: "https://3a0d7451b1984cd780bdbe6e465a3712@o100578.ingest.sentry.io/4505109969829888",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App({context}) {

  const queryClient = new QueryClient();

  return (
    <div className="App p-4">
      <header className="App-header">
        <QueryClientProvider client={queryClient}>
          <ValidationUI context={context}></ValidationUI>
          </QueryClientProvider>
      </header>
    </div>
  );
}

export default App;
