import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

window.addEventListener('message', (event) => {

  if(event.data.type !== "setup")
    return;

  let context = event.data.context;
  
  const current_url_string = window.location.href;
  const current_url = new URL(current_url_string);
  var env_id = current_url.searchParams.get("configuration_uuid");
  context.env_id = env_id;

  // const context = {
  //   "view": "change_events.detail",
  //   "id": "9987803",
  //   "company_id": 90971,
  //   "project_id": 1981897,
  //   "env_id": env_id
  // }
  console.log('Message from Procore: ', event);
  
  if(!event.origin.includes("procore"))
    root.render(
      <>
        <br />
        <h1 className="m-3">Not connected to Procore</h1>
      </>
      
    );
  else
    root.render(
      <React.StrictMode>
        <App context={context} />
      </React.StrictMode>
    );
  
});

window.parent.postMessage({ type: 'initialize' }, '*');



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
